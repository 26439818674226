import React from 'react';
import PropTypes from 'prop-types';
import { BASE } from '../../basedata/constants';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { Button, InputGroup } from 'react-bootstrap';

import { PiMagnifyingGlassLight } from "react-icons/pi";

const EventCreationForm = ({ formData, handleChange, nextStep }) => {

  const MySwal = withReactContent(Swal)

  const errorAddEventModal = () => {
    MySwal.fire({
      icon: "error",
      title: "Algo não deu certo!",
      text: "Atualize a pagina e tente novamente",
    });
  }
  
  const onSubmit = (data: any) => {

    const companyId = sessionStorage.getItem('company')

    fetch(`${BASE.api.base_url_localhost}${BASE.api_dashboard.event_group_create}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': BASE.api.api_key
        },
        body: JSON.stringify({
            "name": data.eventName,
            "status": "active",
            "isActive": true,
            "companyId": companyId,
            "eventType": data.eventType,
        })
    })
        .then((res) => res.json())
        .then((response) => {
            formData.eventGroup = response.event
        })
        .catch((error) => {
          errorAddEventModal()
          console.log(error)
        })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData)
    nextStep();
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>





      <div className="table-action-search mb-3">
        {/* <label htmlFor="table-search" className="sr-only">Search</label> */}

          <PiMagnifyingGlassLight/>

        <input type="text" placeholder="Jogo:" className="form-control d-inline" id="eventName" name="eventName" value={formData.eventName} onChange={handleChange} />
      </div>






        {/* <div className="mb-3">
          <label htmlFor="eventType" className="form-label text-dark">Tipo</label>
          <select className="form-select" id="eventType" name="eventType" value={formData.eventType} onChange={handleChange}>
            <option className="text-dark" value="">Escolha uma opção</option>
            <option className="text-dark" value="evento">Evento</option>
            <option className="text-dark" value="jogo">Jogo</option>
          </select>
        </div> */}
        <div className="text-center">
        <Button
            type="submit"
            className="mb-0 w-100"
        >
            Avançar
        </Button>

        </div>
      </form>
    </div>
  );
};

EventCreationForm.propTypes = {
  formData: PropTypes.shape({
    eventName: PropTypes.string,
    eventType: PropTypes.string,
    eventGroup: PropTypes.string
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default EventCreationForm;
