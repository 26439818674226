import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, ProgressBar, Modal, Button, Form } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'



import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { UserCreate } from '../components/userCreate'
// import { OrganizationCreate } from './createOrganization'
import { useForm } from 'react-hook-form'
import { EventCreate } from './createEvent'
import { AddProvider } from './addProvider'
import SupplierBiometryTable from '../components/supplierBiometryTable'
import { SingleCheckIn } from './singleCheckIn'
import { SingleCreateUser } from './singleCreateUser'


import { PiMegaphoneLight } from "react-icons/pi";
import { GoChevronDown } from "react-icons/go";
import { PiMagnifyingGlassLight } from "react-icons/pi";
import { LiaFileDownloadSolid } from "react-icons/lia";
import { LiaTimesSolid } from "react-icons/lia";
import { TbDoorEnter, TbReport } from "react-icons/tb";
import { BsPen } from "react-icons/bs";
import { AiOutlineUsergroupAdd, AiOutlineForm, AiOutlineMinusCircle } from "react-icons/ai";
import { MdOutlineFileDownload, MdReport } from "react-icons/md";
import { TbReportSearch } from "react-icons/tb";


import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { SupplierUsersCountItemList } from '../components/SupplierUsersCountItemList'

ChartJS.register(ArcElement, Tooltip, Legend);


function AddProviderToEvent() {
    // const auth = sessionStorage.getItem('auth')

    // if (auth !== 'true') {
    //   window.location.href = '/'
    // }

    // const [userList, setUserList] = useState<any[]>([])



    const dateConvert = (date: any) => {
      const eventDate = new Date(date)

      const options = {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
      }

      setEventDataTransformed({
        dataTransformDay: eventDate.getDate().toString().padStart(2, '0'),
        dataTransformMonth: new Intl.DateTimeFormat("pt-BR", { month: "short"}).format(eventDate),
        dataTransformTime: `${eventDate.getHours()}:${eventDate.getMinutes().toString().padStart(2, '0')}`,
      })
      return `${eventDate.toLocaleDateString(
          'pt-br'
      )} ${eventDate.getHours()}:${eventDate.getMinutes().toString().padStart(2, '0')}`
  }



    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()


    const [eventNumber, setEventNumber] = useState(0)

    const [disableSign, setDisableSign] = useState(false)


    const [deletSupplier, setDeletSupplier] = useState<any>([])

    const [isLoading, setLoading] = useState(false)

    const [acummulated, setAcummulated] = useState<any[]>([])

    const [totalUsers, setTotalUsers] = useState({
        withBiometry: 0,
        withoutBiometry: 0,
        count: 0
    })

    const [show, setShow] = useState(false);



    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [showOrg, setShowOrg] = useState(false);

    const handleShowOrg = () => setShowOrg(true)
    const handleCloseOrg = () => setShowOrg(false)


    const [showConfirm, setShowConfirm] = useState(false);

    const handleShowConfirm = () => setShowConfirm(true)
    const handleCloseConfirm = () => setShowConfirm(false)


    const [eventData, setEventData] = useState<any>({})
    const [eventDataTransformed, setEventDataTransformed] = useState<any>({})

    const [supplierdata, setSupplierdata] = useState<any>({})
    const [userBiometryCount, setUserBiometryCount] = useState<any>({})

    const confirExcludeSupplier = () => {
        handleCloseConfirm()

        setLoading(true)
        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.supplier_delete}/${deletSupplier[0]}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }, body: JSON.stringify({
                eventId: deletSupplier[1]
            })
        })
            .then((resg) => resg.json())
            .then((response) => {
                setLoading(false)
                getEventDetails(eventId)
            })
            .catch((error) => {
                setLoading(false)
                console.log(error)
            })
    }

    const getEventUsers = (eventId) => {
      fetch(`${BASE.EVENT.url}${BASE.EVENT.event_users_count}/${eventId}`, {
        method: 'GET',
      })
        .then((resg) => resg.json())
        .then((response) => {
          setUserBiometryCount(response.event)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  
    const getEventGroups = (eventId) => {
      fetch(`${BASE.EVENT.url}${BASE.EVENT.event_suppliers}/${eventId}`, {
        method: 'GET',
      })
        .then((resg) => resg.json())
        .then((response) => {
          setSupplierdata(response)
        })
        .catch((error) => {
          console.log(error)
        })
    }

    
    const getEventDetails = (eventId) => {
      fetch(`${BASE.EVENT.url}${BASE.EVENT.info}/${eventId}`, {
        method: 'GET',
      })
        .then((resg) => resg.json())
        .then((response) => {
          setEventData(response.event)
          dateConvert(response.event.eventDate)
          getEventGroups(response.event.eventCode)
          getEventUsers(response.event.eventCode)
        })
        .catch((error) => {
          console.log(error)
        })
    }
            
            
    const onSubmit = (data: any) => {
        setDisableSign(true)

        // const username = string_to_slug(data.name)

        fetch(`${BASE.api.base_url}${BASE.api.access_create}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                name: data.name.replace(/^\s+|\s+$/g, ''),
                email: data.email.replace(/^\s+|\s+$/g, ''),
                password: `AM${Math.random().toString(36).slice(-8)}!`,
                role: data.type || 'default'
            })
        })
            .then((res) => res.json())
            .then((response) => {
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const getEventEntrances = (eventId) => {
        fetch(`${BASE.api_dashboard.base_url}${BASE.api_dashboard.event_access}/${eventId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: CONFIG.API_KEY
            }
        })
            .then((resg) => resg.json())
            .then((response) => {
                if (eventNumber !== response) {
                    setEventNumber(response)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const resetForm = () => {
        setDisableSign(false)
        reset()
    }
    const { eventId } = useParams()

    useEffect(() => {
        getEventDetails(eventId)
    }, [])

    function biometricTotalHandler(total, done) {
        // console.log(total, done)
        const temptotal = totalUsers.count + total
        const tempwithbio = totalUsers.withBiometry + done
        setAcummulated([{ total, done }])

        console.log(acummulated)
        setTotalUsers({
            withBiometry: tempwithbio,
            count: temptotal,
            withoutBiometry: temptotal - tempwithbio
        })
    }

     
  const data = {
    // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        label: 'Pessoas',
        data: [1, 10],
        backgroundColor: [
          'rgba(69, 185, 235, 1)',
          'rgba(180, 180, 180, 0.2)',
        ],
        spacing: 5,
        weight: 8,
        cutout: '80%'
        // clip: {left: 15, top: false, right: -2, bottom: 0}
      },
    ],
 
  };

  const options:any = {
    cutout: {
      percentage: 80, // Adjust this value to control the thickness of the donut ring
    },
  };


    return (
        <>


            <Row>
                <Col sm={12} xl={8}>

                <div className="event-action-top">
                  <div className="event-action-top-info">
                  <div className="square-button rounded-sm">
                      <span className="event-info-month">
                      {eventDataTransformed.dataTransformMonth}
                      </span>
                      <span className="event-info-date">
                        {eventDataTransformed.dataTransformDay}
                      </span>
                    </div>
                    <div className="square-button event-title rounded-sm">
                      <span className="event-info-time">
                      {eventDataTransformed.dataTransformTime}
                      </span>
                      <span className="event-info-title">
                        {eventData.title}
                      </span>

                </div>
                  </div>

                  <div className='event-action-top-buttons'>


                  <div className="square-button all-event-buttom rounded-sm">
                      <span className="big-counter">{supplierdata.totalGroups || 0}</span>
                      <span>Camarotes</span>
                    </div>

                    <div className="square-button rounded-sm report-button">
                      <i>
                        <TbReportSearch />
                      </i>
                      <span>Baixar Relatório</span>
                    </div>
                  </div>    
                </div>

                <ul className="group-detail-list">

            {supplierdata.groups && supplierdata.groups.map(item => (
            <SupplierUsersCountItemList 
            key={item.id}
            supplierName={item.name}
            supplierId={item.id}
            eventId={eventData?.eventCode}
            />
              ))
            }

                </ul>


                



                </Col>

                <Col md={6} xl={4}>
                    <AddProvider />
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Criar coordenador</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    placeholder="Nome Completo"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>E-mail (opcional)</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="E-mail"
                                    {...register('email')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um E-mail válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Telefone (opcional)</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Telefone"
                                    {...register('phone')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um Telefone válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Button
                                        disabled={disableSign}
                                        type="submit"
                                        className="mb-0 w-100"
                                        onClick={() => clearErrors()}
                                    >
                                        Cadastrar Usuário
                                    </Button>
                                </Form.Group>
                            </Col>
                            <Col>
                                {disableSign && (
                                    <Form.Group>
                                        <Button
                                            className="mb-0 btn-success w-100"
                                            onClick={() => resetForm()}
                                        >
                                            Cadastrar Outro
                                        </Button>
                                    </Form.Group>
                                )}
                            </Col>
                        </Row>
                        {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Criar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showOrg} onHide={handleCloseOrg}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar Evento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    placeholder="Nome Completo"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Local</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder=""
                                    {...register('email')}
                                />
                            </Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Data/Hora</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder=""
                                    {...register('phone')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um Telefone válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Button
                                        disabled={disableSign}
                                        type="submit"
                                        className="mb-0 w-100"
                                        onClick={() => clearErrors()}
                                    >
                                        Atualizar
                                    </Button>
                                </Form.Group>
                            </Col>

                        </Row>
                        {/* <Row>
                <p>Não foi possível cadastrar o usuário</p>
              </Row> */}
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseOrg}>
                        Fechar
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showConfirm} onHide={handleCloseConfirm}>
                <Modal.Body>
                    Tem certeza?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseConfirm}>
                        Sair
                    </Button>
                    <Button variant="danger" onClick={confirExcludeSupplier}>
                        Excluir
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddProviderToEvent
