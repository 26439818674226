import React, { Component, useCallback, useEffect, useState } from 'react'
import { BASE } from '../../basedata/constants'

import { Row, Col, Card, Tab, Tabs, Table } from 'react-bootstrap'

import Modal from 'react-bootstrap/Modal';

import './event.sass'

import { Link, useParams } from 'react-router-dom'

import { initFlowbite } from 'flowbite'

// import * as XLSX from 'xlsx';
import { utils, read, writeFile } from 'xlsx';
import Dropzone from 'react-dropzone';

import { PiMegaphoneLight } from "react-icons/pi";
import { GoChevronDown } from "react-icons/go";
import { PiMagnifyingGlassLight } from "react-icons/pi";
import { LiaFileDownloadSolid } from "react-icons/lia";
import { LiaTimesSolid } from "react-icons/lia";
import { FcInvite } from "react-icons/fc";


import faceOK from '../../images/icon-smile.svg'
import faceNOK from '../../images/icon-smile-dotted.svg'

export default function ShowEventProvider() {


  const { eventId, supplierId } = useParams()

    const [loading, setLoading] = useState(false)

    // User list uploader
    const [tempUsersToLoad, setTempUsersToLoad] = useState<any>(null);
    const [tempUserCheck, setTempUserCheck] = useState<any>(null);
    const [usersListColumns, setUsersListColumns] = useState<any>([]);


    //User validation response
    const [responseCounter, setResponseCounter] = useState({
        error: 0,
        success: 0,
        return: false
    });
    const [errorList, setErrorList] = useState<any>({
        simpleList: [],
        fullList: []
    });

    //User list
    const [userCommonList, setUserCommonList] = useState<any>(null);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [totalPages, setTotalPages] = useState(1);
    const [usersTotal, setUsersTotal] = useState(0);

    //List handlers
    const [userIdHandlerList, setUserIdHandlerList] = useState<any>([]);
    const [searchBar, setSearchBar] = useState('null')
    const [searchTimeout, setSearchTimeout] = useState<any>(null)
    const [searchData, setSearchData] = useState<any>({});

    const getEventUsers = (searchTerm:any = null) => {
        // /eventUsers/:eventId/:supplierId

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                page,
                pageSize,
                search: searchTerm
            })
        };
        fetch(`${BASE.EVENT.url}${BASE.EVENT.users_provider}/${eventId}/${supplierId}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setUserCommonList(data.result)
                setTotalPages(Math.ceil(data.totalItems / pageSize));
                setUsersTotal(data.totalItems || 0)
            });
    }
    
    const handleFileUpload = (acceptedFiles:any) => {


      setTempUsersToLoad(null);
      setTempUserCheck(null)
      
      setResponseCounter({
          error: 0,
          success: 0,
          return: false
      })
      setErrorList({
          simpleList: [],
          fullList: []
      })


      const fileReader = new FileReader();
      fileReader.onload = (e:any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = read(data, { type: 'array' });
  
        // Assuming the first sheet is the one you want to parse
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
  
        // Convert sheet to JSON
        const jsonData = utils.sheet_to_json(sheet);
  
        // Log rows by columns
        // jsonData.forEach((row) => {
        //   Object.keys(row).forEach((column) => {
        //     console.log(`Column: ${column}, Value: ${row[column]}`);
        //   });
        // });
        

        setShow(false)
        document.getElementById('orla')?.click()
        setTempUsersToLoad(jsonData);
        setTempUserCheck(jsonData[0])
      };
  
      fileReader.readAsArrayBuffer(acceptedFiles[0]);
    };

    const verifyUsersJson = () => {
    setLoading(true)
    const newUserField:any = []
    // Object.keys(tempUserCheck).forEach((column, index) => {
        
        for(const itemList of tempUsersToLoad){
            let requestObject = {};
        for(let currentColumn of usersListColumns){
        currentColumn = currentColumn.split('|')
            const currentItemIndex = Object.keys(itemList).indexOf(currentColumn[1])
            if(currentItemIndex >= 0){
                requestObject = {
                    ...requestObject,
                    [currentColumn[0]]: itemList[currentColumn[1]]
                }
            }
            // continue
        }
        newUserField.push(requestObject)
    }

    console.log('| | | | | ')
    console.log(newUserField)
    console.log('|+ |+ |+ |+ |+ ')


    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            eventId: eventId,
            supplierId: supplierId,
            userList: newUserField
        })
    };
    fetch(`${BASE.USER.url}${BASE.USER.create_bulk}`, requestOptions)
        .then(response => response.json())
        .then(data => {
            // setTempUserCheck(null)
            setLoading(false)
            setResponseCounter({
                error: data.countError || 0,
                success: data.countSuccess || 0,
                return: true
            })
            setErrorList({
                simpleList: data.errorData.slice(0,4),
                fullList: data.errorData
            })
            getEventUsers()
        });
    }

    const deleteUserFromList = (selectedIndex:any) => {
        setTempUserCheck(null)
        const _tempUserCheck = tempUserCheck;
        delete _tempUserCheck[selectedIndex]


        setTimeout(() => {
            if(Object.keys(_tempUserCheck).length < 1){
                console.log('oiee')
                return setTempUserCheck(null)
            }
            console.log(selectedIndex)
            console.log(tempUserCheck)
            setTempUserCheck(_tempUserCheck)
        }, 100);
    }

    const selectColumnName = (item:any, column:any, key:any) => {
        const _tempUserListColumns:any = usersListColumns
        const regex = new RegExp(`${item.target.value}|${column}|${key}`);

        if(_tempUserListColumns.length > 0){
            if(regex.test(_tempUserListColumns.join())){
                _tempUserListColumns[key] = `${item.target.value}|${column}|${key}`
            }else{
                _tempUserListColumns.splice(key,0, `${item.target.value}|${column}|${key}`)
            }
            setUsersListColumns(_tempUserListColumns)
        }else{
            _tempUserListColumns.splice(key,0, `${item.target.value}|${column}|${key}`)
        }
    }

    const downloadErrorSheet = () => {
        const worksheet = utils.json_to_sheet(errorList.fullList);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        writeFile(workbook, `error_list.xlsx`);
    }

    const removeUsersFromEvent = (idList:any) => {

        // getEventUsers()
        // /eventUsers/:eventId/:supplierId

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                ids: idList
            })
        };
        fetch(`${BASE.EVENT.url}${BASE.EVENT.remove_user}/${eventId}/${supplierId}`, requestOptions)
            .then(response => response.json())
            .then(data => {
               window.location.reload()
            });
    }

    const removeBiometries = (idList:any) => {

        // getEventUsers()
        // /eventUsers/:eventId/:supplierId

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                ids: idList
            })
        };
        fetch(`${BASE.BIOMETRY.url}${BASE.BIOMETRY.remove}`, requestOptions)
            .then(response => response.json())
            .then(data => {
                alert('Biometrias deletadas!')
                getEventUsers()
            });
    }

    useEffect(() => {
        getEventUsers()
        initFlowbite()
    }, [page])

    useEffect(() => {
        initFlowbite()
    }, [ userCommonList])

    const checkHandler = (event:any, userId:any) => {
        let newUserIdList:any = userIdHandlerList
        if (newUserIdList.indexOf(userId) >= 0) {
            newUserIdList = newUserIdList.filter((item:any) => item !== userId)
        } else {
            newUserIdList.push(userId)
        }
        setUserIdHandlerList(newUserIdList)
        getEventUsers()
      };

      function handlePrev() {
        setPage(page - 1);
      }
    
      function handleNext() {
        setPage(page + 1);
      }
    
      function getPageNumbers() {

        const numbers: any = [page];
    
    
        if (totalPages <= 6) {
          for (let i = 2; i <= totalPages; i++) {
            if(i === page){
                continue
            }
            numbers.push(i);
          }
        } else {
    
          for (let i = 2; i <= totalPages - 1; i++) {
    
            if (i === page - 1) {
              numbers.push(Number(i));
            } else if (i === page + 1) {
              numbers.push(i);
            }
          }
        }
    
    
        return numbers.sort(function (a: any, b: any) {
          return a - b;
        });
      }

    const searchBox = (event:any) => {
        const value = event.target.value;
        setSearchBar(value);
        setSearchData(null)
    
        // Clear the previous timeout
        clearTimeout(searchTimeout);
    
        // Set a new timeout
        const timeout = setTimeout(() => {
          console.log(`La bosqueta: ${value}`)
          getEventUsers(value)
        }, 1000); 
    
        setSearchTimeout(timeout);
    }

    const sendMessage = (idsToSend:any, messageType:any) => {


        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                type: messageType,
                userList: idsToSend
            })
        };
        fetch(`https://hml-service-jaiminho-74hf2txj6q-rj.a.run.app/messages`, requestOptions)
            .then(data => {
               alert('Mensagem Enviada')
            });

    }

    const modalthings = () => {
        // const $targetEl = document.getElementById('default-modal');
        // const options = {
        //     closable: true,
        //     onHide: () => {
        //         console.log('modal is hidden');
        //     },
        //     onShow: () => {
        //         console.log('modal is shown');
        //     },
        //     onToggle: () => {
        //         console.log('modal has been toggled');
        //     },
        // };
        // // instance options object
        // const instanceOptions = {
        //     id: 'default-modal',
        //     override: false
        // };

        // const modal = new Modal($targetEl, options, instanceOptions);
        // const modal = new Modal(document.getElementById('default-modal')).hide();

        // console.log('> < > < ')
        // const modal = document.getElementById('default-modal');
        // modal?.classList.remove('hidden')

   


        // modal.hide();
    }


    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [onboardingLinkText, setOnboardinLinkText] = useState('Link Onboarding')
    const getOnboardingLink = (token:any) => {

      navigator.clipboard.writeText(`https://demo.bepass.com.br/?token=${token}`)

      setOnboardinLinkText('Link Copiado!')
      setTimeout(() => {
        setOnboardinLinkText('Link Onboarding')
      }, 2000);
      return 
    }


    const [eventDetail, setEventDetail] = useState<any>(null);
    const [eventDataTransformed, setEventDataTransformed] = useState<any>({})

    const dateConvert = (date: any) => {
      const eventDate = new Date(date)

      const options = {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
      }

      setEventDataTransformed({
        dataTransformDay: eventDate.getDate().toString().padStart(2, '0'),
        dataTransformMonth: new Intl.DateTimeFormat("pt-BR", { month: "short"}).format(eventDate),
        dataTransformTime: `${eventDate.getHours()}:${eventDate.getMinutes().toString().padStart(2, '0')}`,
      })
      return `${eventDate.toLocaleDateString(
          'pt-br'
      )} ${eventDate.getHours()}:${eventDate.getMinutes().toString().padStart(2, '0')}`
  }

    const getEventDetail = () => {
      const requestOptions = {
          method: 'Get',
      };
      fetch(`${BASE.EVENT.url}${BASE.EVENT.info}/${eventId}`, requestOptions)
          .then(response => response.json())
          .then(data => {
            dateConvert(data.event.eventDate)
            setEventDetail(data.event)
          });
  }

  useEffect(() =>{
    getEventDetail()
  },[])

  return (
<>
<div className='all-page sm:ml-64'>
   <div className='all-page-content'>

    <div className='grid lg:grid-cols-8 md:grid-cols-3 gap-4 mb-4'>
    <div className="col-span-3">
        {/* <EventHeaderSnippet /> */}
    </div>

    {/* <NextEventButton/> */}


{/* acept modal */}
    {/* <div id="popup-modal" className="fixed top-0 left-0 right-0 z-50  p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div className="relative w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow">
            <button type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center" data-modal-hide="popup-modal">
                <svg className="w-3 h-3"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span className="sr-only">Close modal</span>
            </button>
            <div className="p-6 text-center">
                <svg className="mx-auto mb-4 text-gray-400 w-12 h-12"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                </svg>
                <h3 className="mb-5 text-lg font-normal text-gray-500">Tem certeza que deseja excluir?</h3>
                <button data-modal-hide="popup-modal" type="button" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2">
                    Tenho sim
                </button>
                <button data-modal-hide="popup-modal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10">Não, cancelar</button>
            </div>
        </div>
    </div>
</div> */}

{/* enviar arquivo */}
<div id="default-modal"   className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full">
    <div className="relative w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow">
            <button id="orla" type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center" data-modal-hide="default-modal">
                <svg className="w-3 h-3"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span className="sr-only">Close modal</span>
            </button>
            <div className="px-6 py-6 lg:px-8">
                <h3 className="mb-4 text-xl font-medium text-gray-900 ">Envie um arquivo</h3>

                <div className='card'>
    <Dropzone onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="modal-drag-drop">
            <input {...getInputProps()} />
            <p>Arraste e solte o arquivo excel (.xlsx) aqui, ou click para selecionar um</p>
          </div>
        )}
      </Dropzone>   
    </div>            
                    <a href="/samples/planilha_exemplo.xlsx" className="sample-link">
                    <span onClick={() => modalthings()}>
                    <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 18">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 1v11m0 0 4-4m-4 4L4 8m11 4v3a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-3"></path>
                    </svg>
                    </span>baixar exemplo
                </a>
            </div>
        </div>
    </div>
</div>

    {/* <div onClick={() => setShowFileModal(true)} className="square-button call-buttom rounded-lg col-start-8">
        <svg className='custom-icon'  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 19">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m9 12 5.419 3.871A1 1 0 0 0 16 15.057V2.943a1 1 0 0 0-1.581-.814L9 6m0 6V6m0 6H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h7m-5 6h3v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-5Zm15-3a3 3 0 0 1-3 3V6a3 3 0 0 1 3 3Z"></path>
        </svg>
        <span>Convocar</span>
    </div> */}



      <Modal className='dropfile-modal' show={show} onHide={handleClose}>
        <Modal.Header>
          <h3>Envie um arquivo</h3>
          <i><LiaTimesSolid/></i>
        </Modal.Header>
        <Modal.Body>

            <div className="dropfile-box">
              <Dropzone onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="modal-drag-drop">
              <input {...getInputProps()} />
              <p>Arraste e solte o arquivo excel (.xlsx) aqui, ou click para selecionar um</p>
              </div>
              )}
              </Dropzone>
            </div>   
    
                <a href="/samples/planilha_exemplo.xlsx" className="sample-link">
                    <i><LiaFileDownloadSolid/></i>
                    baixar exemplo
                </a>
        </Modal.Body>
      </Modal>

  <div className="event-action-top">
  
  {eventDetail && (
  <div className="event-action-top-info">
  <div className="square-button rounded-sm">
    <span className="event-info-month">
    {eventDataTransformed.dataTransformMonth}
    </span>
    <span className="event-info-date">
      {eventDataTransformed.dataTransformDay}
    </span>
  </div>
  <div className="square-button event-title rounded-sm">
    <span className="event-info-time">
    {eventDataTransformed.dataTransformTime}
    </span>
    <span className="event-info-title">
      {eventDetail.title}
    </span>
  </div>
</div>
  )}


  <div className='event-action-top-buttons'>
    <div onClick={handleShow} className="square-button call-buttom rounded-sm">
        <i className='custom-icon'>
          <FcInvite/>
        </i>
        <span>Convidar</span>
    </div>


    <div className="square-button all-event-buttom rounded-sm">
      <span className="big-counter">{usersTotal} / 20</span>
      <span>Convidados</span>
    </div>
  </div>    
  </div>


    
    {/* <div className="square-button all-event-buttom rounded-lg col-start-8">
        <svg className='custom-icon'  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path fill="currentColor" d="M6 1a1 1 0 0 0-2 0h2ZM4 4a1 1 0 0 0 2 0H4Zm7-3a1 1 0 1 0-2 0h2ZM9 4a1 1 0 1 0 2 0H9Zm7-3a1 1 0 1 0-2 0h2Zm-2 3a1 1 0 1 0 2 0h-2ZM1 6a1 1 0 0 0 0 2V6Zm18 2a1 1 0 1 0 0-2v2ZM5 11v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 11v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM10 15v-1H9v1h1Zm0 .01H9v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 15v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM15 11v-1h-1v1h1Zm0 .01h-1v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM5 15v-1H4v1h1Zm0 .01H4v1h1v-1Zm.01 0v1h1v-1h-1Zm0-.01h1v-1h-1v1ZM2 4h16V2H2v2Zm16 0h2a2 2 0 0 0-2-2v2Zm0 0v14h2V4h-2Zm0 14v2a2 2 0 0 0 2-2h-2Zm0 0H2v2h16v-2ZM2 18H0a2 2 0 0 0 2 2v-2Zm0 0V4H0v14h2ZM2 4V2a2 2 0 0 0-2 2h2Zm2-3v3h2V1H4Zm5 0v3h2V1H9Zm5 0v3h2V1h-2ZM1 8h18V6H1v2Zm3 3v.01h2V11H4Zm1 1.01h.01v-2H5v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H5v2h.01v-2ZM9 11v.01h2V11H9Zm1 1.01h.01v-2H10v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM9 15v.01h2V15H9Zm1 1.01h.01v-2H10v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H10v2h.01v-2ZM14 15v.01h2V15h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM14 11v.01h2V11h-2Zm1 1.01h.01v-2H15v2Zm1.01-1V11h-2v.01h2Zm-1-1.01H15v2h.01v-2ZM4 15v.01h2V15H4Zm1 1.01h.01v-2H5v2Zm1.01-1V15h-2v.01h2Zm-1-1.01H5v2h.01v-2Z"></path>
        </svg>
        <span>Todos<br/>Eventos</span>
    </div> */}

    </div>
    
    {(tempUserCheck && !responseCounter.return) && (

    <div className="upload-check-table">
        <h2 className='table-title'>Verifique os campos e dados enviados</h2>
    <div className="relative overflow-x-auto">
    <table className="w-full text-sm text-left text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
                <th scope="col" className="px-6 py-3">
                    Coluna no Arquivo
                </th>
                <th scope="col" className="px-6 py-3">
                    Pré-visualização
                </th>
                <th scope="col" className="px-6 py-3">
                    Campo
                </th>
                <th scope="col" className="px-6 py-3">
                    
                </th>
            </tr>
        </thead>
        <tbody>

        {Object.keys(tempUserCheck).map((column, key) =>  (

                <tr key={key} className="bg-white border-b  ">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap ">
                    {column}
                </th>
                <td>
                    {tempUserCheck[column]}
                </td>
                <td>
                <select
                    onChange={(item) => selectColumnName(item,column, key)}
                >
                <option>Selecione</option>
                    <option value="name">Nome</option>
                    <option value="document">CPF</option>
                    <option value="email">E-mail</option>
                    <option value="phoneNumber">Celular</option>
                    {/* <option value="sector">Setor</option>
                    <option value="function">Função</option>
                    <option value="externalId">Código Interno</option>
                    <option value="company">Empresa</option> */}
                </select>
                </td>
                <td>
                    <button onClick={() => deleteUserFromList(column)} className="btn-muted btn-exclude">excluir</button>
                </td>
            </tr>

            )
            )
        }

  
        </tbody>

        <tfoot>
           <tr>
           <td colSpan={4} className='align-right'>
            {loading ? (
                <div className="bar-loader active"></div>
            ) :(
            <button onClick={() => verifyUsersJson()} type="button" className="button-default rounded-md">
                Salvar
                <svg className='button-icon'  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 16">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m2.707 14.293 5.586-5.586a1 1 0 0 0 0-1.414L2.707 1.707A1 1 0 0 0 1 2.414v11.172a1 1 0 0 0 1.707.707Z"/>
                </svg>
            </button>
            )}
            </td>
           </tr>
        </tfoot>
    </table>
</div>

    </div>
  )
}


{responseCounter.return && 
<>
<h2 className='table-title'>Dados enviados com sucesso!</h2>
<Row>

  <Col sm={6}>

  <div className="sample-error-list">
    <table>
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
            <tr>
                <th scope="col">
                  Cadastros com erro
                </th>
                <th scope="col">
                    Erro
                </th>
            </tr>
        </thead>
        <tbody>
        {errorList.simpleList && 
        errorList.simpleList.map((item:any, index) => (
            <tr key={index} className="bg-white">
            <th scope="row">
            <div>
                    <div className="text-base font-semibold">{item.name || '--'}</div>
                    <div className="font-normal text-gray-500">{item.document || '--'}</div>
                </div>  
            </th>
            <td>
                {item.message}
            </td>
        </tr>
        ))}
        </tbody>

        <tfoot>
           <tr>
           <td colSpan={4} className='align-right'>
            <button onClick={() => downloadErrorSheet()} type="button" className="button-default btn-info rounded-md">
                Baixar Todos
                <svg className="button-icon"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1.707 2.707 5.586 5.586a1 1 0 0 0 1.414 0l5.586-5.586A1 1 0 0 0 13.586 1H2.414a1 1 0 0 0-.707 1.707Z"></path>
                </svg>
            </button>
            </td>
           </tr>
        </tfoot>
    </table>
</div>

   

  </Col>
  <Col sm={3}>

    <div className="show-numbers error-numbers rounded-lg">
        <div className="big-numbers">
            {responseCounter.error || 0}
        </div>
        <p className="numbers-description">
            Pessoas com erro no cadastro
        </p>
    </div>


  </Col>
  <Col sm={3}>

    <div className="show-numbers rounded-lg">
        <div className="big-numbers">
           {responseCounter.success || 0}
        </div>
        <p className="numbers-description">
            Pessoas cadastradas com sucesso!
        </p>
    </div>

  </Col>
</Row>




</>

}







<div className="people-table">
  <h2 className='table-title'>Pessoas convidados</h2>

    <div className="table-action-bar">
      <span>.</span>
{/*     
      <button id="dropdownDefaultButton" data-dropdown-toggle="dropdown" className="button-dropdown" type="button">
        Ações
        <GoChevronDown />
      </button>


    <div id="dropdown" className="hidden dropdown-box">

        <ul className="dropdown-list" aria-labelledby="dropdownDefaultButton">
          <li>
            <a onClick={() => sendMessage(userIdHandlerList, 'whats')} className="block px-4 py-2 hover:bg-gray-100">Enviar Whatsapp</a>
          </li>
          <li>
            <a onClick={() => sendMessage(userIdHandlerList, 'email')} className="block px-4 py-2 hover:bg-gray-100">Enviar E-mail</a>
          </li>
          <li>
            <a onClick={() => sendMessage(userIdHandlerList, 'sms')} className="block px-4 py-2 hover:bg-gray-100">Enviar SMS</a>
          </li>
        </ul>  

        <ul className="dropdown-list dropdown-list-hr" aria-labelledby="dropdownDefaultButton">
          <li>
            <a onClick={() => removeBiometries(userIdHandlerList)} className="block px-4 py-2 hover:bg-gray-100">Remover Biometria</a>
          </li>
          <li>
            <a onClick={() => removeUsersFromEvent(userIdHandlerList)} className="block px-4 py-2 hover:bg-gray-100">Excluir</a>
          </li>
        </ul>
      </div>
 */}


      <div className="table-action-search">
        <label htmlFor="table-search" className="sr-only">Search</label>
        <i>
          <PiMagnifyingGlassLight/>
        </i>
        <input onChange={searchBox} type="text" id="table-search-users" placeholder="Buscar usuários (Nome ou CPF)" />
      </div>
    </div>
    
    <Table responsive hover>
        <thead className="table-people-header">
            <tr>
             <th scope="col">
                    {/* <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500    focus:ring-2  "/> */}
                    {/* <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label> */}
                </th> 
                <th scope="col">
                    Dados
                </th>
                <th scope="col">
                    Biometria
                </th>
                {/* <th scope="col">
                    Função
                </th>
                <th scope="col">
                    Setor
                </th> */}
                <th colSpan={2} scope="col">
                    Action
                </th>
            </tr>
        </thead>
        <tbody className='table-people-data'>
        {userCommonList?.length > 0 ? (
           userCommonList.map((item:any) => (
              <tr key={item.id}>
                <td>
                    {/* <div className="flex items-center">
                        <input checked={userIdHandlerList.indexOf(item.id) >= 0 ? true:false} onChange={(e) => checkHandler(e, item.id)} id={item.id} type="checkbox" />
                        <label htmlFor={item.id} className="sr-only">checkbox</label>
                    </div> */}
                </td>
                <td scope="row" className="table-people-info">
                    <img src={faceOK} alt={item.firstName} />
                    <div>
                        <h3>{item.firstName} {item.lastName}</h3>
                        <h4>{item.document}</h4>
                    </div>  
                </td>

                <td>
                    <i className="status-circle nok"></i> Falta
                </td>

                {/* <td>
                    {item.externalId || 'N/A'}
                </td>
                <td>
                {item.accessCode || 'N/A'}
                </td> */}
                <td className='table-people-single-action'>
                    <p className='share-text'>enviar onboaridng:</p>
                {/* <button onClick={() => sendMessage([item.id], 'whats')} type="button" className="button-default button-share rounded-md">
                    whatsapp
                </button> */}
                <button  onClick={() => sendMessage([item.id], 'email')} type="button" className="button-default button-share rounded-md">
                    e-mail
                </button>
                <button  onClick={() => getOnboardingLink(item.token)} type="button" className="button-default button-share rounded-md">
               
                                                    {onboardingLinkText}
                </button> 
                </td>
                <td>
                    <button onClick={() => removeUsersFromEvent([item.id])} className='fake-link soft-exclude'>
                        excluir
                    </button>
                </td>
            </tr>
           ))):(
            <tr>
                <td colSpan={8}>
                <div className="p-4 mb-4 text-sm text-yellow-800 rounded-lg bg-yellow-50 text-center" role="alert">
                    <span className="font-medium">:(</span> Nenhuma pessoa encontrada para o evento.
                </div>
                </td>
            </tr>
           )
            }
        </tbody>
            {userCommonList?.length > 0 &&
        <tfoot className='hidden'>
           <tr>
            <td colSpan={6}>
            <nav aria-label="navigation">
            <ul className="flex pagination items-center justify-center h-10 text-base">
                <li key={'prev'}>
                    <button disabled={page === 1} onClick={handlePrev} className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700">
                        <span className="sr-only">Previous</span>
                        <svg className="w-3 h-3 rtl:rotate-180"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4"/>
                        </svg>
                    </button>
                </li>

                {
              (page > 1) && (
                <li key="uno" className={`${page === 1 ? 'active': ''}`} onClick={() => { setPage(1); getPageNumbers() }}>
                    <a href="#" className={`flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700`}>1</a>
                </li>
              )}
            {(page > 4) && (
              <li>
                <p className="text-lg font-medium text-gray-900 dark:text-white">...</p>
              </li>
            )
            }

                {getPageNumbers().map((pageItem: any) =>
                <li className={`${page === pageItem ? 'active':''}`} key={pageItem} onClick={() => { setPage(pageItem); getPageNumbers() }}>
                    <a href="#" className={`flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700`}>{pageItem}</a>
                </li>
                )}



            {(page < (totalPages - 3)) && (
            <li>
                <p className="text-lg font-medium text-gray-900 dark:text-white">...</p>
              </li>
            )
            }
            {
             (page < (totalPages - 3)) && (
                <li key="zero" className={`${page === totalPages ? 'active': ''}`} onClick={() => { setPage(totalPages); getPageNumbers() }}>
                <a href="#" className={` flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700`}>{totalPages}</a>
            </li>
              )
            }


                <li key={'next'} >
                <button disabled={page === totalPages} onClick={handleNext} className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700">
                    <span className="sr-only">Next</span>
                    <svg className="w-3 h-3 rtl:rotate-180"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                    </svg>
                </button>
                </li>
            </ul>
            </nav>
            </td>
           </tr>
        </tfoot>
           }
    </Table>
    {/* <!-- Edit user modal  tabIndex="-1" --> 
    <div id="editUserModal"   className="fixed top-0 left-0 right-0 z-50 items-center justify-center hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
        <div className="relative w-full max-w-2xl max-h-full">
            <form action="#" className="relative bg-white rounded-lg shadow ">
                <div className="flex items-start justify-between p-4 border-b rounded-t ">
                    <h3 className="text-xl font-semibold text-gray-900 ">
                        Edit user
                    </h3>
                   <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center  " data-modal-hide="editUserModal">
                    <svg className="w-3 h-3"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                    <span className="sr-only">Close modal</span>
                </button>
                </div>
                <div className="p-6 space-y-6">
                    <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="first-name" className="block mb-2 text-sm font-medium text-gray-900 ">First Name</label>
                            <input type="text" name="first-name" id="first-name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5      " placeholder="Bonnie" />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="last-name" className="block mb-2 text-sm font-medium text-gray-900 ">Last Name</label>
                            <input type="text" name="last-name" id="last-name" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5      " placeholder="Green" />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">Email</label>
                            <input type="email" name="email" id="email" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5      " placeholder="example@company.com" />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="phone-number" className="block mb-2 text-sm font-medium text-gray-900 ">Phone Number</label>
                            <input type="number" name="phone-number" id="phone-number" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5      " placeholder="e.g. +(12)3456 789" />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="department" className="block mb-2 text-sm font-medium text-gray-900 ">Department</label>
                            <input type="text" name="department" id="department" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5      " placeholder="Development" />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="company" className="block mb-2 text-sm font-medium text-gray-900 ">Company</label>
                            <input type="number" name="company" id="company" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5      " placeholder="123456" />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="current-password" className="block mb-2 text-sm font-medium text-gray-900 ">Current Password</label>
                            <input type="password" name="current-password" id="current-password" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5      " placeholder="••••••••" />
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                            <label htmlFor="new-password" className="block mb-2 text-sm font-medium text-gray-900 ">New Password</label>
                            <input type="password" name="new-password" id="new-password" className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5      " placeholder="••••••••" />
                        </div>
                    </div>
                </div>
                <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b ">
                    <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center   ">Save all</button>
                </div>
            </form>
        </div>
    </div>
    */}
</div>
  
      </div>
</div>

 {/*  Alert */}
    {/* <div className="flex p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50  " role="alert">
  <svg className="flex-shrink-0 inline w-4 h-4 mr-3 mt-[2px]"  xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
  </svg>
  <span className="sr-only">Info</span>
  <div>
    <span className="font-medium">Ensure that these requirements are met:</span>
      <ul className="mt-1.5 ml-4 list-disc list-inside">
        <li>At least 10 characters (and up to 100 characters)</li>
        <li>At least one lowercase character</li>
        <li>Inclusion of at least one special character, e.g., ! @ # ?</li>
    </ul>
  </div>
</div> */}


</>
  )
}
