import React, { useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import EventCreationForm from './eventCreationForm';
import EventSessionForm from './eventSessionForm';
import EventInformationForm from './eventInformationForm';

import './eventGroupCreationWizard.sass'

const EventGroupCreationWizard = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    eventName: '',
    eventType: 'jogo',
    sessionName: '',
    sessionDateTime: '',
    sessionDuration: '',
    visitorTeamLogo: null,
    matchDateTime: '',
    eventGroup: ''
  });

  const nextStep = () => {
    if (formData.eventType === 'evento') {
      setStep(2);
    } else if (formData.eventType === 'jogo') {
      setStep(3);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Formulário submetido:', formData);
  };

  let stepComponent;
  switch (step) {
    case 1:
      stepComponent = <EventCreationForm formData={formData} handleChange={handleChange} nextStep={nextStep} />;
      break;
    case 2:
      stepComponent = <EventSessionForm formData={formData} handleChange={handleChange} nextStep={nextStep} />;
      break;
    case 3:
      stepComponent = <EventInformationForm formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} />;
      break;
    default:
      stepComponent = null;
  }

  return (
    <Card>
      <Card.Header>
          <Card.Title as="h5">Criar próximos Eventos</Card.Title>
      </Card.Header>
        <Card.Body>
          <Row>
            <Col>
              {stepComponent}
            </Col>
          </Row>
        </Card.Body>
    </Card>
  );
};

export default EventGroupCreationWizard;

