import React, { Component, useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Tab, Tabs, Table, ProgressBar, Form, Button, InputGroup, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import graphTest from '../../images/test-graph.png'

import './DashboardCoordinator.sass'

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'
import { useForm } from 'react-hook-form'
import { EventList } from '../Events/eventList'

import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { SupplierUsersCountCard } from '../components/SupplierUsersCountCard'
import { FaUsersViewfinder } from "react-icons/fa6";
import { MdCompareArrows } from "react-icons/md";

import { FiChevronsUp } from "react-icons/fi";
import { FaAnglesUp } from "react-icons/fa6";


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { AdminSupplierUsersCountCard } from '../components/AdminSupplierUsersCountCard'


function DashCoordinator() {


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: false,
    },
  },
};

const labels = ['20:35','20:40', '20:45', '20:50', '20:55', '21:00', '21:05', '21:10', '21:15', '21:20', '21:25', '21:30', '21:35'];


const pacoca = [1,2,3,4]

const [fakeChartData, setFakeChartData] = useState<any>({})

  const [eventData, setEventData] = useState<any>({})

  const [supplierdata, setSupplierdata] = useState<any>({})
  const [userBiometryCount, setUserBiometryCount] = useState<any>({})
  
  const getNextEvent = () => {
    fetch(`${BASE.EVENT.url}${BASE.EVENT.next_event_info}`, {
      method: 'GET',
    })
      .then((resg) => resg.json())
      .then((response) => {
        setEventData(response.event)
        getEventGroups(response.event.eventCode)
        getEventUsers(response.event.eventCode)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getEventUsers = (eventId) => {
    fetch(`${BASE.EVENT.url}${BASE.EVENT.event_users_count}/${eventId}`, {
      method: 'GET',
    })
      .then((resg) => resg.json())
      .then((response) => {
        setUserBiometryCount(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getEventGroups = (eventId) => {
    fetch(`${BASE.EVENT.url}${BASE.EVENT.event_suppliers}/${eventId}`, {
      method: 'GET',
    })
      .then((resg) => resg.json())
      .then((response) => {
        setSupplierdata(response)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getNextEvent()

    setTimeout(() => {
      setFakeChartData({
        labels,
        maintainAspectRatio: false,
        aspectRatio: 2,
        responsive: false,
        datasets: [
          {
            label: 'Fluxo de acessos',
            data: labels.map(() => faker.datatype.number({ min: 0, max: 300 })),
            backgroundColor: 'rgba(215, 0, 8, 0.5)',
          }
        ],
      })
    }, 1000);
  }, [])


  const dateFormatter = (dateTime:string)=>{

    const options:any = {
      year: '2-digit',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    };

    const oldDate = new Date(dateTime)
    return oldDate.toLocaleDateString('pt-BR', options).split(',').join(' ')
  }

    return (
      <div className="main-content">

        <div className="main-event">
          <div className="event-header">
            <div className="event-data">
              <h1 className="event-name">{eventData?.title}</h1>
              <h2 className="event-date">{dateFormatter(eventData?.eventDate)}</h2>
            </div>

          </div>
          <div className="event-content">
  
          {/* <div className="event-ticket-total event-header-content-box">
              <div className="event-header-content-box-main">
                Pessoas Convocadas
                <div className='event-ticket-counter'>9999</div>
              </div>
              <div className='event-ticket-button'>
                    visualizar
                    <div className="event-icon">
                      <AiOutlineUsergroupAdd/>
                    </div>
                  </div>
            </div> */}

  
            <div className="event-biometry-total event-header-content-box">
              <div className="event-header-content-box-main">
                Já entraram
                <div className='event-ticket-counter'>991</div>
              </div>
              <div className="event-icon biometry-firulita">
                      <MdCompareArrows/>
                    </div>
              <div className="event-header-content-box-main">
                Total de convidados
                <div className='event-ticket-counter'>2218</div>
              </div>
            </div>
          </div>



          <Row className='chart-list'>

<Col xs={7}>
<div className="main-chart">
{fakeChartData?.labels && (
  <Bar options={options} data={fakeChartData} />
)}
</div>
  </Col>
  <Col xs={4}>
<h2 className='event-subtitle user-list-title'>
  Últimos acessos
  </h2>
<ul className="user-entrance-list">
  {pacoca.map((item, index) => (
  <li key={index}>
    <div className="user-avatar">
      <img src={avatar1} alt="Mengo" />
    </div>
    <div className="user-details">
      <h5>
        {faker.person.fullName()}
        <span className='user-consessionary'>camarote {faker.datatype.number({ min: 0, max: 300 })}</span>
      </h5>
      <span>{dateFormatter(`${faker.date.recent(2)}`)}</span>
    </div>
    <div className="entrance-icon">
      <i><FaAnglesUp /></i>
    </div>
  </li>
    ))
  }
  </ul>
</Col>
</Row>



          <div className="event-groups">
       
            <h2 className='event-subtitle'>
              Camarotes
            </h2>
            <ul className="event-groups-list row">
            {supplierdata.groups && supplierdata.groups.map(item => (
            <AdminSupplierUsersCountCard 
            key={item.id}
            supplierName={item.name}
            supplierId={item.id}
            eventId={eventData?.eventCode}
            />
              ))
            }
            </ul>
          </div>
        </div>
      </div>
    )
}

export default DashCoordinator
