// EventInformationForm.js
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import InsertLogoModal from './insertLogoModal';
import SelectLogoModal from './selectLogoModal';
import { BASE } from '../../basedata/constants';


import { PiMagnifyingGlassLight } from "react-icons/pi";

const EventInformationForm = ({ formData, handleChange }) => {
  const [showInsertModal, setShowInsertModal] = useState(false);
  const [showSelectModal, setShowSelectModal] = useState(false);

  const handleModalClose = () => {
    setShowSelectModal(false);
  };

  const handleOpenSelectModal = () => {
    setShowSelectModal(true);
  };

  const createEvent = (data: any) => {

    const companyId = sessionStorage.getItem('company')

    fetch(`${BASE.api.base_url_localhost}${BASE.api_dashboard.event_create}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': BASE.api.api_key
        },
        body: JSON.stringify({
            "name": data.eventGroup.name,
            "eventDate": data.matchDateTime,
            "status": "idle",
            "isActive": true,
            "companyId": companyId,
            "eventGroup": data.eventGroup,
            "locationId": "8fe5c94a-5990-48bd-808d-47b24dc5e7f4"
        })
    })
        .then((res) => res.json())
        .then((response) => {
          response;
        })
        .catch((error) => {
          console.error(error)
        })
  }

  function addDuration(time: string, duration: string): string {
    const [horas, minutos] = time.split(':').map(Number);
    
    const duracao = parseInt(duration, 10);
    
    const minutosTotais = horas * 60 + minutos + duracao;
    
    const horasNovas = Math.floor(minutosTotais / 60);
    const minutosNovos = minutosTotais % 60;
    
    const novoTempoStr = `${horasNovas.toString().padStart(2, '0')}:${minutosNovos.toString().padStart(2, '0')}`;
    
    return novoTempoStr;
  }

  const createCalendar = (data: any) => {

    const dateTimeArray = data.matchDateTime.split('T')
    const date = dateTimeArray[0]
    const time = dateTimeArray[1]
    const endTime = addDuration(time, '90')

    fetch(`http://127.0.0.1:3103/calendar`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': BASE.api.api_key
        },
        body: JSON.stringify({
          "summary": data.eventGroup.name,
          "start": {
            date: date, 
            time: time
          },
          "end": {
            date: date,
            time: endTime
          }
        })
    })
        .then((res) => res.json())
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          console.error(error)
        })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    createEvent(formData)
    createCalendar(formData)
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        {/* <div className="mb-3">
          <Button className='btn-secondary' onClick={handleOpenSelectModal}>
            Selecionar o logo do time visitante
          </Button>
        </div> */}

        {/* <InputGroup className='mb-3'>
        <div className="table-action-search">
        <label htmlFor="table-search">Logo visitante:</label>
          <i>
            <PiMagnifyingGlassLight/>
          </i>
          <input type="file" id="table-search-users" placeholder="Nome" />
        </div>
        </InputGroup> */}

      <InputGroup className='mb-3 table-action-search'>
        <div className="">
        <label htmlFor="table-search">Código MCA:</label>
          <input type="text" className="form-control"  />
        </div>
        </InputGroup>

        <InputGroup className='mb-3 table-action-search'>
        <div className="">
        <label htmlFor="table-search">Data | Hora da Partida:</label>
          <input type="datetime-local" className="form-control" id="matchDateTime" name="matchDateTime" value={formData.matchDateTime} onChange={handleChange} />
        </div>
        </InputGroup>







        <Button
            type="submit"
            className="mb-0 w-100"
        >
            Salvar
        </Button>
        
{/* 
        <div className="mb-3">
          <label htmlFor="matchDateTime" className="form-label text-dark">Dia | Hora da Partida</label>
          <input type="datetime-local" className="form-control" id="matchDateTime" name="matchDateTime" value={formData.matchDateTime} onChange={handleChange} />
        </div>
        <div className="text-center">
          <button type="submit" className="btn btn-dark fs-5">Enviar</button>
        </div> */}
      </form>

      <SelectLogoModal showModal={showSelectModal} handleModalClose={handleModalClose} />
    </div>
  );
};

EventInformationForm.propTypes = {
  formData: PropTypes.shape({
    matchDateTime: PropTypes.string.isRequired,
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default EventInformationForm;

