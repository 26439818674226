import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BASE } from '../../basedata/constants';
import { useForm } from 'react-hook-form';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const EventSessionForm = ({ formData, handleChange}) => {
  const MySwal = withReactContent(Swal)

  const [newSession, setNewSession] = useState({
    sessionName: '',
    sessionDateTime: '',
    sessionDuration: ''
  });

  const resetForm = () => {
    setNewSession({
      sessionName: '',
      sessionDateTime: '',
      sessionDuration: ''
    });
  };

  const createEvent = (data: any) => {

    const companyId = sessionStorage.getItem('company')

    fetch(`${BASE.api.base_url_localhost}${BASE.api_dashboard.event_create}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': BASE.api.api_key
        },
        body: JSON.stringify({
            "name": data.sessionName,
            "eventDate": data.sessionDateTime,
            "status": "active",
            "isActive": true,
            "companyId": companyId,
            "eventGroup": formData.eventGroup
        })
    })
        .then((res) => res.json())
        .then((response) => {
          createCalendar(data)
          addEventModal(data.sessionName)
          resetForm();
        })
        .catch((error) => {
          errorAddEventModal()
          console.error(error)
        })
  }

  const addEventModal = (eventName: string) => {
    MySwal.fire(`Sessão ${eventName} incluida`);
  }

  const errorAddEventModal = () => {
    MySwal.fire({
      icon: "error",
      title: "Algo não deu certo!",
      text: "Revise os dados e tente novamente",
    });
  }

  const createCalendar = (data: any) => {

    const dateTimeArray = data.sessionDateTime.split('T')
    const date = dateTimeArray[0]
    const time = dateTimeArray[1]
    const endTime = data.sessionDuration

    fetch(`http://127.0.0.1:3103/calendar`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-api-key': BASE.api.api_key
        },
        body: JSON.stringify({
          "summary": data.sessionName,
          "start": {
            date: date, 
            time: time
          },
          "end": {
            date: date,
            time: endTime
          }
        })
    })
        .then((res) => res.json())
        .then((response) => {
          response
        })
        .catch((error) => {
          console.error(error)
        })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    createEvent(newSession)
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSession((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSend = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  return (
    <div>
       <h4 className='text-secondary'>Sessões</h4>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="sessionName" className="form-label text-dark">Nome da Sessão</label>
          <input
            type="text"
            className="form-control"
            id="sessionName"
            name="sessionName"
            value={newSession.sessionName}
            onChange={handleInputChange} />
        </div>
        <div className="mb-3">
          <label htmlFor="sessionDateTime" className="form-label text-dark">Dia | Hora</label>
          <input
            type="datetime-local"
            className="form-control"
            id="sessionDateTime"
            name="sessionDateTime"
            value={newSession.sessionDateTime}
            onChange={handleInputChange} />
        </div>
        <div className="mb-3">
          <label htmlFor="sessionDuration" className="form-label text-dark">Duração</label>
          <input
            type="time"
            className="form-control"
            id="sessionDuration"
            name="sessionDuration"
            value={newSession.sessionDuration}
            onChange={handleInputChange} />
        </div>
        <div className="mb-3 text-end">
          <button type="submit" className="btn btn-secondary"
          > Incluir Sessão </button>
        </div>
        <div className="text-center">
          <button type="button" className="btn btn-dark fs-5" onClick={handleSend}>Enviar</button>
        </div>
      </form>
    </div>
  );

};

EventSessionForm.propTypes = {
  formData: PropTypes.shape({
    sessionName: PropTypes.string.isRequired,
    sessionDateTime: PropTypes.string.isRequired,
    sessionDuration: PropTypes.string.isRequired,
    eventGroup: PropTypes.string.isRequired
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
};

export default EventSessionForm;
