import React, { useEffect, useState } from 'react'
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    InputGroup,
    FormControl,
    DropdownButton,
    Dropdown,
    Table,
    Modal,
    ButtonToolbar,
    ButtonGroup
} from 'react-bootstrap'

import avatar1 from '../../assets/images/user/avatar-1.jpg'
import avatar2 from '../../assets/images/user/avatar-2.jpg'
import avatar3 from '../../assets/images/user/avatar-3.jpg'

import { CONFIG } from '../../config/configVariables'
import { BASE } from '../../basedata/constants'

import './accessLog.sass'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { AiOutlineUsergroupAdd, AiOutlineForm, AiOutlineMinusCircle } from "react-icons/ai";

import InputMask from 'react-input-mask';

interface Props {
    supplierId: any,
    companyId: any
}

export function CoordinatorRow({ supplierId, companyId }: Props) {

    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        clearErrors,
        getValues,
        reset
    } = useForm()

    const [show, setShow] = useState(false);

    const [currentCoordinator, setCurrentcurrentCoordinator] = useState<any>({});


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showConfirm, setShowConfirm] = useState(false);

    const handleShowConfirm = () => setShowConfirm(true)
    const handleCloseConfirm = () => setShowConfirm(false)

    const [coordinatorList, setCoordinatorList] = useState<any[]>([])


    const getCoordinator = () => {

        console.log('mamaeu', supplierId, companyId)

        fetch(`${BASE.USER.url}${BASE.USER.coordinator_search}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                originCompanyId: companyId,
                supplierId: supplierId
            })
        })
            .then((res) => res.json())
            .then((response) => {
                if (response.length > 0)
                    setCoordinatorList(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const editCoordinator = (data: any) => {

        const temp_name = data.name.replace(/^\s+|\s+$/g, '').split(' ')
        fetch(`${BASE.USER.url}${BASE.USER.user}/${data.id}`, {
            method: 'Put',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': BASE.api.api_key
            },
            body: JSON.stringify({
                firstName: temp_name[0],
                lastName: temp_name.slice(1).join(" "),
                email: data.email.replace(/^\s+|\s+$/g, '') || 'a@a.com',
                phoneNumber: data.phoneNumber
                    .replace(/"/g, '')
                    .replace(/'/g, '')
                    .replace(/\(|\)/g, ''),
                "document": data.document.replace(/^\s+|\s+$/g, ''),
                "document_type": "CPF",
                "operator": data.operator ? true : false,
                "supplierId": supplierId,
                "originCompanyId": companyId,
                "externalId": "coordenador",
                "gender": "na",
                "birthDate": "1990-01-01"
            })
        })
            .then((res) => res.json())
            .then((response) => {
                window.location.reload();
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const excludeCoordinator = (userId: any, userEmail: any) => {


        fetch(`${BASE.USER.url}${BASE.USER.user}/${userId}`, {
            method: 'DELETE',
            headers: {
                Authorization: CONFIG.API_KEY
            }
        })
        .then((resg) => resg.json())
        .then((response) => {
            console.log(response)
        })
        .catch((error) => {
            console.log(error)
        })


        fetch(`${BASE.COMPANY.url}${BASE.COMPANY.create_role}/${userId}`, {
          method: 'DELETE',
          headers: {
              Authorization: CONFIG.API_KEY
          }
      })
      .then((resg) => resg.json())
      .then((response) => {
        window.location.reload()
      })
      .catch((error) => {
          console.log(error)
      })
    }



    useEffect(() => {
        getCoordinator()
    }, [])

    if (!coordinatorList) {
        return (<></>)
    }
    return (
        <ul className='coordinator-list'>
            {coordinatorList &&
                coordinatorList.map((item, index) => (
                    <li key={index} className='cordinator-row'>

                      <ul className="group-action">
                        <li onClick={() => { setCurrentcurrentCoordinator(item), handleShow() }}>
                            <span className="sidebar-icon"><AiOutlineForm/></span>
                        </li>
                        <li onClick={() => { setCurrentcurrentCoordinator(item), handleShowConfirm() }}>
                            <span className="sidebar-icon icon-red"><AiOutlineMinusCircle/></span>
                        </li>
                      </ul>

                      <p>{`${item.firstName} ${item.lastName}`} | {item.email} | {item.phoneNumber}</p>


                        {/* <td>
                            <Link to="#" onClick={() => { setCurrentcurrentCoordinator(item), handleShow() }} className="biomeliy-status link-coordinator">
                                Editar coordenador
                            </Link>
                        </td>
                        <td colSpan={2}>
                            <h6 className="text-muted text-coordinator m-t-10">
                                {`${item.firstName} ${item.lastName}`} | {item.email} | {item.phoneNumber}
                            </h6>

                        </td>
                        <td className='text-center'>
                            <Link to="#" onClick={() => { setCurrentcurrentCoordinator(item), handleShowConfirm() }} className="link-coordinator text-c-red m-l-15">
                                excluir
                            </Link></td> */}
                    </li>
                ))}
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit(editCoordinator)}>
                    <Form.Control
                        type="hidden"
                        defaultValue={currentCoordinator.id}
                        {...register('id')}
                    />
                    <Form.Control
                        type="hidden"
                        defaultValue="true"
                        {...register('operator')}
                    />

                    <Modal.Header closeButton>
                        <Modal.Title>Criar/Editar coordenador</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>Nome</Form.Label>
                                <Form.Control
                                    defaultValue={`${currentCoordinator.firstName} ${currentCoordinator.lastName}`}
                                    placeholder="Nome Completo"
                                    {...register('name', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um Nome.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">

                            <Col>
                                <Form.Label>CPF</Form.Label>
                                <Form.Control
                                    placeholder="Documento (CPF)"
                                    defaultValue={currentCoordinator.document}
                                    {...register('document', { required: true })}
                                />
                                {errors.name && (
                                    <span className="label-error">
                                        Você precisa informar um CPF.
                                    </span>
                                )}</Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>E-mail</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="E-mail"
                                    defaultValue={currentCoordinator.email}
                                    {...register('email', { required: true })}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um E-mail válido
                                    </span>
                                )}</Col>

                        </Row>
                        <Row className="mb-3">
                            <Col><Form.Label>Telefone (opcional)</Form.Label>
                                <Form.Control
                                    placeholder="Telefone"
                                    defaultValue={currentCoordinator.phoneNumber}
                                    {...register('phoneNumber')}
                                />
                                {errors.email && (
                                    <span className="label-error">
                                        Você precisa informar um Telefone válido
                                    </span>
                                )}</Col>

                        </Row>


                        <ButtonToolbar
                            className="justify-content-between"
                            aria-label="Toolbar with Button groups"
                        >
                            <ButtonGroup aria-label="First group">
                                <Button variant="default" onClick={handleClose}>
                                    <h6 className="text-muted text-coordinator m-t-10">
                                        resetar senha
                                    </h6>
                                </Button>
                            </ButtonGroup>
                            <ButtonGroup aria-label="Last group">
                                <Button variant="default" onClick={handleClose}>
                                    Fechar
                                </Button>
                                <Button variant="success" type='submit'>
                                    Salvar
                                </Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Modal.Body>
                </Form>

            </Modal>



            <Modal show={showConfirm} onHide={handleCloseConfirm}>
                <Modal.Body>
                    Tem certeza que deseja excluir {currentCoordinator.lastName}?
                </Modal.Body>
                <Modal.Footer>
                    <Form id="exclude" onSubmit={handleSubmit(excludeCoordinator)}>

                        {/* <Form.Control
                            type="hidden"
                            defaultValue={currentCoordinator.id}
                            {...register('id')}
                        />
                        <Form.Control
                            type="hidden"
                            {...register('operator')}
                        /> */}

                        <Button variant="secondary" onClick={handleCloseConfirm}>
                            Sair
                        </Button>
                        <Button variant="danger" onClick={() => excludeCoordinator(currentCoordinator.id, currentCoordinator.email)}>
                            Excluir
                        </Button>
                    </Form>
                </Modal.Footer>
            </Modal>
        </ul>
    )
}
